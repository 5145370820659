// extracted by mini-css-extract-plugin
export var active = "slideshow-module--active--LGZfQ";
export var button = "slideshow-module--button--BuGTa";
export var container = "slideshow-module--container--FqbJr";
export var genHeader = "slideshow-module--gen-header--GjkVa";
export var h1 = "slideshow-module--h-1--by8Cc";
export var h2 = "slideshow-module--h-2--NCYnv";
export var h3 = "slideshow-module--h-3--g-xwK";
export var indicator = "slideshow-module--indicator--pIQ8e";
export var p1 = "slideshow-module--p-1--4eyYN";
export var p2 = "slideshow-module--p-2--VCnB+";
export var p3 = "slideshow-module--p-3--NUHTy";
export var right = "slideshow-module--right--EZHCR";
export var scroller = "slideshow-module--scroller--gQWkN";
export var slide = "slideshow-module--slide--zR-vv";